import React, { Fragment, useEffect, useState, useRef } from 'react';
import '../../My-Learning/myLearning.scss';
import '../../My-Learning/Chapters/chapters.scss';
import Slider from "react-slick";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BubbleLoader } from '../../../Loader/loader';
import ProgressBar from '../../../utils/progressBar';
import { getSkillPods, getTestdata, getQuestion, selectAnswer, testSubmitted, closeQuiz, getCertificat } from '../../../Redux/ActionCreators/contentLibraryAction';
import { showSideBar } from '../../../Redux/ActionCreators/manageContentAction';
import { SampleNextArrow, SamplePrevArrow, exitFullscreen, goFullScreen } from '../../../utils/miscelleneous';
import { useTranslation } from 'react-i18next';
import QuizPopup from '../../Practice-Test/quizPopup';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import Certificate from '../../../utils/certificate';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export default function SkillPods() {
    const [quiz, setQuiz] = useState(false);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const [count, setCount] = useState(0);
    const [time, setTime] = useState(null); // Initial time in seconds (5 minutes)
    const [timerRunning, setTimerRunning] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('');
    const location = useLocation();
    const sliderRef = useRef(null);
    const { t } = useTranslation();
    const timer = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(showSideBar());
        dispatch(getSkillPods(location.state.id));

    }, [])

    const dispatch = useDispatch();
    const { skillPods, isBubbleLoading, skillDetails, isTest, individualResource, questions, question, score, certificateData } = useSelector(state => state.contentLibrary);


    const navigate = useNavigate();
    const { detail, title, content } = location.state

    const goToTopics = () => {
        navigate('/content-library/topics', {
            state: {
                id: skillPods[0]?._id,
                chapter: skillPods[0]?.title,
                subject: location.state.title,
                skillId: location.state.id,
                type: location.state.type,
                detail: skillPods[0],
                content: location.state.content
            }
        })
    }

    useEffect(() => {
        return () => {
            clearInterval(timer.current);
        };
    }, []);

    const goToQuestion = async (ques) => {
        window.scrollTo(0, 0);
        // Clear the existing timer
        clearInterval(timer.current);
        // Reset the timer to 0
        timer.current = 0;
        setQuiz(true);
        setQuizCompleted(false);
        setTime(individualResource?.time * 60);
        startTimer();
        dispatch(getQuestion(ques));
    };

    useEffect(() => {
        let timer;

        if (timerRunning && time > 0) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }


        return () => {
            clearInterval(timer);
        };
    }, [timerRunning, time]);

    const startTimer = () => {
        setTimerRunning(true);
    };

    const resetTimer = () => {
        setTime(0); // Reset time to 5 minutes
        setTimerRunning(false);
        testSubmited();
    };

    useEffect(() => {
        if (time === 0) {
            resetTimer();
        }
    }, [time]);

    const getNext = (count) => {
        setCount(count + 1);
        dispatch(getQuestion(questions[count + 1]));
    };

    const getPrev = (count) => {
        setCount(count - 1);
        dispatch(getQuestion(questions[count - 1]));
    };

    const testSubmited = async (id) => {
        let obj = {
            test_id: individualResource?.test_id,
            "anti_cheat": false,
            "error_count": 0,
            data: questions?.filter(el => el.answer !== null)?.map(el => [{ q_id: el._id, a_id: el.answer }][0])
        }

        await dispatch(testSubmitted(obj));
        setQuizCompleted(true);
        setQuiz(false);
        clearInterval(timer);
    };

    const closeQuizpopup = async () => {
        dispatch(closeQuiz());
        exitFullscreen();
        setCount(0);
        setQuiz(false);
        setQuizCompleted(false);
    };

    useEffect(() => {
        if (isTest) {
            document.addEventListener('contextmenu', function (e) {
                e.preventDefault();
            });

            // Prevent Ctrl+S, Ctrl+C & Ctrl+V
            document.onkeydown = function (e) {
                e = e || window.event; //Get event
                if (e.ctrlKey) {
                    var c = e.code; //Get key code
                    if (["KeyS", "KeyC", "KeyV"].includes(c)) {
                        e.preventDefault();
                        e.stopPropagation();
                        setSuccessMessage(true);
                        setSuccessMessageText('Ctrl + S, Ctrl+C, Ctrl+V not allowed');
                        // setCount(count + 1);
                    }
                }
                if (e.keyCode === 123) {
                    return false;
                }

                // disable I key
                if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
                    return false;
                }

                // disable J key
                if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
                    return false;
                }

                // disable U key
                if (e.ctrlKey && e.keyCode === 85) {
                    return false;
                }
            };

            // detect tab switching
            document.addEventListener("visibilitychange", (event) => {
                if (document.visibilityState !== "visible" && count + 1 !== questions?.length) {
                    setSuccessMessage(true);
                    setSuccessMessageText('Tab switching not allowed');
                    // setCount(count + 1);
                }
            });

            // full screen detection - chrome
            document.addEventListener("webkitfullscreenchange", function () {
                // full screen disabled
                if (!document.fullscreenElement && count + 1 !== questions?.length) {
                    setSuccessMessage(true);
                    setSuccessMessageText('Exiting Full Screen not allowed');
                    // setCount(count + 1);
                    goFullScreen();
                }
            });
        }

        return (() => {
            document.removeEventListener("visibilitychange", (event) => {
                if (document.visibilityState !== "visible" && count + 1 !== questions?.length) {
                    setSuccessMessage(true);
                    setSuccessMessageText('Tab switching not allowed');
                    //   setCount(count + 1);
                }
            });
            document.removeEventListener("webkitfullscreenchange", function () {
                // full screen disabled
                if (!document.fullscreenElement && count + 1 !== questions?.length) {
                    setSuccessMessage(true);
                    setSuccessMessageText('Exiting Full Screen not allowed');
                    //   setCount(count + 1);
                }
                goFullScreen();
            });
        })
    });

    const getCertificate = async () => {
        const res = await dispatch(getCertificat(detail?._id));
        if (res?.type === 'GET_CERT_DATA') {
            downloadcertificatePdf();
            // navigate(`certificate`, { state: { id: detail?._id } })
        }
    }

    const downloadcertificatePdf = () => {
        const input = document.getElementById('cert');
        html2canvas(input, { logging: true, allowTaint: false, useCORS: true, proxy: 'https://v2-dev.elitelearning.io/' })
            .then((canvas) => {
                var pdf = new jsPDF("l", "mm", "a4");   //orientation: landscape
                var imgData = canvas.toDataURL('image/png');
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'PNG', 0, 0, width, 170);
                pdf.save(`certificate.pdf`);
            })
    }



    const snackClosed = () => {
        setSuccessMessage(false);
        setSuccessMessageText('');
    }

    const settings1 = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    };
    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={successMessage}
                onClose={() => snackClosed()}
                autoHideDuration={4000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {successMessageText}
                </Alert>
            </Snackbar>
            <div className='chapter-module-holder'>
                <div className="bc-nav ml-2 mt-2" >
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {/* <li className="breadcrumb-item"> <img src='/images/home.svg' alt='' /></li> */}
                            <li className="breadcrumb-item cursor-pointer" onClick={() => navigate(-1)} >{t('Content Library')}</li>
                            <li className="breadcrumb-item  b-active">{location.state.title}</li>
                        </ol>
                    </nav>
                </div>

                <div className='chapter-description'>
                    <div className='chapter-img'>
                        <img src={detail?.image} alt='' />
                    </div>
                    <div className='chapter-desc'>
                        <div className=''>
                            <h3>
                                {detail?.title}
                            </h3>&nbsp;&nbsp;
                            <p className=''>
                                <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} top={-18} completed={0} />
                            </p>

                        </div>
                        <div className='sub-description'>
                            {detail?.description}
                        </div>
                        <div className='chapter-count'>
                            <div className='d-flex align-items-center'>
                                <img src='/images/video-icon.svg' alt='' />
                                <div className='mx-2'>
                                    <h6>{t('Videos')}</h6>
                                    <h5>{detail?.videos}</h5>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <img src='/images/article-icon.svg' alt='' />
                                <div className='mx-2'>
                                    <h6>{t('Articles')}</h6>
                                    <h5>{detail?.articles}</h5>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <img src='/images/quiz-icon.svg' alt='' />
                                <div className='mx-2'>
                                    <h6>{t('Quizzes')}</h6>
                                    <h5>{!content ? detail?.quizzes : 0}</h5>
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <img src='/images/document-icon.svg' alt='' />
                                <div className='mx-2'>
                                    <h6>{t('Documents')}</h6>
                                    <h5>{detail?.documents}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='chapters-holder'>
                    <h4>{t('Skill Pods')}
                        <aside onClick={goToTopics}>{t('View all')}</aside>
                    </h4>
                    {skillPods?.length > 0 &&
                        <div className={'bubble-card mt-2'} style={{ marginLeft: -5 }}>
                            <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap mr-2'} style={{ overflowY: window.innerWidth > 767 ? '' : 'hidden' }}>
                                {
                                    window.innerWidth > 767 ?
                                        <div className=''>
                                            <Slider {...settings1} ref={c => (sliderRef)}>
                                                {isBubbleLoading ?
                                                    <BubbleLoader /> :
                                                    skillPods?.map((el) => {
                                                        return (
                                                            <div key={el._id} className='card' style={{ width: '20.5%', marginRight: 10 }}>
                                                                <Link to={`/content-library/topics`}
                                                                    state={{
                                                                        id: el._id,
                                                                        chapter: el.title,
                                                                        subject: location.state.title,
                                                                        skillId: location.state.id,
                                                                        type: location.state.type,
                                                                        detail: el,
                                                                        content: content
                                                                    }}
                                                                >
                                                                    <img className="card-img-top" src={el.image} style={{ height: 118 }} alt="Card" />
                                                                    {/* {el.resources === 0 ? null :
                                                                        <div className='time'>
                                                                            <img src='images/duration.svg' alt='' />&nbsp;
                                                                            {convertMinsToHrsMins(((el.resources) * 4), t)}
                                                                        </div>
                                                                    } */}
                                                                    <div className="card-body p-0">
                                                                        <div style={{ padding: 7, paddingTop: 5, paddingRight: 7, }}>
                                                                            <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                                <h5 className='elipsis-2' >
                                                                                    {el.title}
                                                                                </h5>
                                                                                <aside className='d-flex mt-1' style={{ fontWeight: 400, fontSize: 10, color: '#101828' }}>{el.resources} {t('Items')}</aside>
                                                                            </div>
                                                                            <div className='mt-3'>
                                                                                <ProgressBar bgcolor='#5855D6' fill='#D9D9D9' t={t} completed={el?.analytic || 0}
                                                                                />
                                                                            </div>
                                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: -5 }}></div>
                                                                            <button>{t('Start learning')}</button>
                                                                        </div>
                                                                    </div>
                                                                </Link>

                                                            </div>

                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                        :
                                        skillPods !== undefined && skillPods.map((el, index) => {
                                            return (
                                                <div key={index} className='card' style={{ width: 230, marginRight: 10 }}>
                                                    <Link to={`/content-library/topics`}
                                                        state={{
                                                            id: el._id,
                                                            chapter: el.title,
                                                            subject: location.state.title,
                                                            skillId: location.state.id,
                                                            type: location.state.type,
                                                            detail: el,
                                                            content: content
                                                        }}
                                                    >
                                                        <img className="card-img-top" src={el.image} alt="Card" height='96px' />
                                                        <div className="card-body p-0">
                                                            <div style={{ padding: 7, paddingTop: 5, paddingRight: 7, }}>
                                                                <div className='d-flex justify-content-center align-items-start flex-column'>
                                                                    <h5 className='elipsis-2' >
                                                                        {el.title}
                                                                    </h5>
                                                                    <aside className='d-flex mt-1' style={{ fontWeight: 400, fontSize: 10, color: '#101828' }}>{el.resources} {t('Items')}</aside>
                                                                </div>
                                                                <div className='mt-3'>
                                                                    <ProgressBar bgcolor='#5855D6' t={t} fill='#D9D9D9' completed={el.analytic || 0}
                                                                    />
                                                                </div>
                                                                <div style={{ borderTop: '1px solid #ddd', marginTop: -5 }}></div>
                                                                <button>{t('Start learning')}</button>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })

                                }

                            </div>
                        </div>
                    }
                </div>

                {location.state.detail?.isPassed ?
                    <div className='test-holder'>
                        <h4>{t('View Certificate')}</h4>
                        <p>{t('Level up on the above skills and collect up to 50 Engagement points and get closer to your database.')}</p>
                        <button onClick={() => getCertificate()}>{t('Download Certificate')}</button>
                    </div> :
                    <div className='test-holder'>
                        <h4>{t('Take a test')}</h4>
                        <p>{t('Level up on the above skills and collect up to 50 Engagement points and get closer to your database.')}</p>
                        <button onClick={() => {
                            setQuiz(false);
                            setQuizCompleted(false);
                            goFullScreen();
                            dispatch(getTestdata(location.state.id, detail?.title))
                        }
                        }>{t('Start Test')}</button>
                    </div>
                }
            </div >

            <div className="feeds-details" style={{ zIndex: window.innerWidth > 767 ? 8 : 9, display: isTest ? "block" : "none", top: 0 }}>
                <QuizPopup
                    type={'test'}
                    t={t}
                    activeFeedData={individualResource}
                    questions={questions}
                    question={question}
                    quiz={quiz}
                    goToQuestion={goToQuestion}
                    selectAnswer={(q_id, a_id) => dispatch(selectAnswer(q_id, a_id))}
                    quizSubmited={testSubmited}
                    count={count}
                    getNext={getNext}
                    getPrev={getPrev}
                    score={score}
                    quizCompleted={quizCompleted}
                    time={time}
                    closeQuizpopup={closeQuizpopup}
                />
            </div>
            <div style={{ zIndex: -1, position: 'fixed' }} >
                <Certificate
                    course={detail?.title}
                    cert={certificateData}
                />
            </div>
        </Fragment>

    )
}
