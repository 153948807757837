import React, { Fragment, useEffect } from 'react';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { convertDate, convertMillisecondsToTime, convertToDate, formatAMPM, getInterest } from '../../utils/miscelleneous';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getAbilityDetails, getReport, getTestGraph } from '../../Redux/ActionCreators/counsellingAction';
import { Table } from 'react-bootstrap';
import { getDescByTitle } from '../../utils/ability';
import TamannaAbility from '../../utils/tamannaAbility.json';
import html2pdf from 'html2pdf.js'

export default function TestReport({ loading, data, back }) {
    const dispatch = useDispatch();
    console.log(data)
    const { t } = useTranslation();

    useEffect(() => {
        const firstRender = async () => {
            if (data?.name === 'riasec') {
                dispatch(getReport())
            } else {
                await dispatch(getAbilityDetails(data?._id));
                await dispatch(getTestGraph(data?._id, data?.name));
            }
        }
        firstRender();
    }, [data])

    const { testReport, abilityDetails, abilityTrends } = useSelector(state => state.counselling);

    const { name, image, createdAt, chart, top3, lastThree, education, interest, interestedJobs } = testReport;

    const downloadReportPdf = () => {
        const input = document.getElementById('report');
        html2canvas(input, { logging: true, allowTaint: false, useCORS: true, proxy: 'https://v2-dev.elitelearning.io/' }).then(function (canvas) {
            try {
                var imgData = canvas.toDataURL('image/png');
                let options = {
                    orientation: "0",
                    unit: "mm",
                    format: "a4"
                };
                var doc = new jsPDF(options, '', '', '');
                doc.addImage(imgData, 'jpeg', 0, 0, 210, 0);
                var corte = 1680;
                var image = canvas.toDataURL('image/png');
                var count = (canvas.height) / corte;
                for (var i = 1; i < count; i++) {
                    doc.addPage();
                    doc.addImage(image, 'png', 0, -330 * i, 210, 0);
                }
                doc.save('report.pdf');
            } catch (e) {
                console.log('error pdf', e);
            }
        });
    }

    const downloadReportPdf1 = () => {

        const element = document.getElementById('report');
        var opt = {
            format: 'A4',
            orientation: 'portrait', // Can also be 'landscape'
            border: {
                top: '5mm',
                right: '5mm',
                bottom: '5mm',
                left: '5mm'
            },
            margin: [0.5, 0.2, 0.5, 0.2],
            filename: `${data?.name}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        // New Promise-based usage:
        html2pdf().from(element).set(opt).save();

        // Old monolithic-style usage:
        html2pdf(element, opt);
    }

    const contentCompletionGraph = {
        chart: {
            type: 'bar',
            width: 550,
            height: 250,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: chart.map(el => el.name),
            title: {
                text: null
            },

            labels: {

                align: 'left',
                reserveSpace: true,
                useHTML: true,
                style: {
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                    color: '#696873',
                    align: 'left'
                }
            }
        },
        yAxis: {
            visible: true,
            title: false,
            // labels: {
            //     enabled: false,
            // },
            gridLineColor: 'transparent',
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold'
                }
            },
        },

        plotOptions: {
            series: {
                borderRadius: 0,
                dataLabels: {
                    align: 'right',
                    format: '{y}%',
                    color: '#ffffff',
                    x: -10
                },
                pointPadding: 0.12,
                groupPadding: 0
            },
            bar: {
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: false,
                    padding: 0,
                    allowOverlap: true,
                    formatter: function () {
                        var pcnt = (this.y);
                        if (pcnt > 0) {
                            return '<tspan style="color:' + this.color + '">' + pcnt + '</tspan>';
                        }
                        else {
                            return '';
                        }
                    }
                }
            }
        },

        credits: {
            enabled: false
        },
        series: [
            {
                enableMouseTracking: false,
                showInLegend: false,
                data: chart
            }
        ]

    }

    //assesment tab graph
    const abilityTrend = {
        chart: {
            width: 600,
            type: 'line',
            height: 400
        },
        title: {
            text: 'Ability Trends'
        },
        subtitle: {
            // text: '3,456 Students'
        },
        xAxis: {
            categories: abilityTrends !== undefined && abilityTrends?.abilities,
            visible: true,
            gridLineWidth: 1
        },
        colors: ['#ECC30B'],
        yAxis: {
            visible: true,
            min: 0,
            gridLineWidth: 1,
            max: 10,
            tickInterval: 1,
            title: {
                text: 'Sten Score'
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                showInLegend: false,
                enableMouseTracking: false
            }
        },
        series: [{
            name: 'Trend',
            data: abilityTrends && abilityTrends?.data
        },
        ]
    }

    return (
        <Fragment>
            {data?.name === 'riasec' ?
                <Fragment>
                    <div className="bc-nav ms-2 mt-2" >
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                {/* <li className="breadcrumb-item"><img src='/images/home.svg' alt='' /></li> */}
                                <li className="breadcrumb-item cursor-pointer" onClick={() => back()} >{t('Career assessment')}</li>
                                <li className="breadcrumb-item  b-active">Test-report</li>
                            </ol>
                        </nav>
                    </div>
                    {loading ? '' :
                        <div className='test-report-holder' id='report'>

                            <h2>Career Assessment Test Report
                                <div className='btns'>
                                    <button onClick={() => downloadReportPdf()}>Download PDF</button>&nbsp;
                                    {/* <button>Share</button> */}
                                </div>
                            </h2>
                            <hr style={{ marginTop: 12, marginRight: 0, border: '0px solid #0000001A' }} />
                            <div className='user-details d-flex justify-content-between'>
                                <div>
                                    <h5>Test taken by : <span style={{ fontWeight: 700 }}> {name}</span></h5>
                                    <h5>Test taken on : <span style={{ fontWeight: 700 }}> {convertDate(createdAt)}</span></h5>
                                    <h5>Test timing : <span style={{ fontWeight: 700 }}> {formatAMPM(createdAt)}</span></h5>
                                </div>
                                <div>
                                    <img src={image} alt='' width={160} />
                                </div>
                            </div>
                            <div className='career-header'>
                                Your Career Interests
                            </div>
                            <div className='career-interests'>
                                <p>Results are in. Results are in a bar graph with six categories: Realistic (R), Investigative (I), Artistic (A), Social (S), Enterprising (E), and Conventional (C). The taller the bar, the stronger your interest in that category. Check out your graph to discover your top interests and potential career paths. Use it as a guide to embrace your unique strengths and explore exciting possibilities ahead!"</p>
                                <div>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={contentCompletionGraph}
                                    />
                                </div>
                            </div>
                            <div className='career-header'>
                                Your top three interest areas
                            </div>
                            <div className='career-interests'>
                                <div className='d-flex align-items-center flex-wrap'>
                                    {
                                        top3 && top3?.map((el, i) => {
                                            return (
                                                <div className='career-interest-progress'>
                                                    <div style={{ padding: 20, width: '25%', backgroundColor: el.type === 'Realistic' ? "#F1FEEB" : el.type === 'Invetigative' ? '#EBFAFE' : el.type === 'Artistic' ? "#EBF4FE" : el.type === 'Social' ? "#FFF3F3" : el.type === 'Enterprising' ? '#FFEBE1' : "#FDF8DF", }}>
                                                        <div className="progress-circular">
                                                            <CircularProgressbarWithChildren
                                                                value={el.value}
                                                                maxValue={100}
                                                                minValue="0"
                                                                styles={{
                                                                    root: {
                                                                        width: "100%",
                                                                        fontSize: 10,
                                                                        borderRadius: 150,
                                                                        marginTop: 0,
                                                                        background: "#fff",
                                                                        verticalAlign: "middle",
                                                                        position: "relative",
                                                                    },
                                                                    path: {
                                                                        strokeLinecap: "square",
                                                                        stroke: el.type === 'Realistic' ? "#38A071" : el.type === 'Investigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e",
                                                                        strokeWidth: 8,
                                                                    },
                                                                    trail: {
                                                                        // Trail color
                                                                        stroke: "#F2f2f3",
                                                                        strokeWidth: 8,
                                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                        strokeLinecap: "round",
                                                                    },
                                                                }}
                                                            >
                                                                <div
                                                                    className="text-center"
                                                                >
                                                                    <p className='text-center text-uppercase' style={{ fontWeight: 600, fontSize: 14, color: el.type === 'Realistic' ? "#38A071" : el.type === 'Investigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e", marginBottom: 10 }}>
                                                                        {el.type}
                                                                    </p>
                                                                </div>
                                                            </CircularProgressbarWithChildren>

                                                        </div>
                                                    </div>
                                                    <div style={{ width: '45%', marginLeft: 30 }}>
                                                        <h6 style={{ color: el.type === 'Realistic' ? "#38A071" : el.type === 'Investigative' ? '#72b7d3' : el.type === 'Artistic' ? "#1d86a3" : el.type === 'Social' ? "#dc5e5f" : el.type === 'Enterprising' ? '#ee824c' : "#dec02e" }}>{el.type} - {el.value}%</h6>
                                                        <p>
                                                            {getInterest(el.type, 'interest')}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            {
                                top3 && top3.concat(lastThree).map((el, i) => {
                                    return (
                                        <div className='suggested-career'>
                                            <div className='d-flex div1' >
                                                {el.type === 'Realistic' ?
                                                    <div className='elite-text-vertical'>
                                                        <span className='characters'>C</span>
                                                        <span className='characters'>I</span>
                                                        <span className='characters'>T</span>
                                                        <span className='characters'>S</span>
                                                        <span className='characters'>I</span>
                                                        <span className='characters'>L</span>
                                                        <span className='characters'>A</span>
                                                        <span className='characters'>E</span>
                                                        <span className='characters'>R</span>
                                                    </div> :
                                                    el.type === 'Investigative' ?
                                                        <div className='elite-text-vertical' style={{ background: '#EBFAFE' }}>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>E</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>V</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>T</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>A</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>G</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>T</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>S</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>E</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>V</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>N</span>
                                                            <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                        </div> :
                                                        el.type === 'Artistic' ?
                                                            <div className='elite-text-vertical' style={{ background: '#EBF4FE' }}>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>C</span>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>I</span>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>T</span>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>S</span>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>I</span>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>T</span>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>R</span>
                                                                <span className='characters' style={{ color: "#1D86A3" }}>A</span>
                                                            </div> :
                                                            el.type === 'Social' ?
                                                                <div className='elite-text-vertical' style={{ background: '#FFF3F3' }}>
                                                                    <span className='characters' style={{ color: "#DC5E5F" }}>L</span>
                                                                    <span className='characters' style={{ color: "#DC5E5F" }}>A</span>
                                                                    <span className='characters' style={{ color: "#DC5E5F" }}>I</span>
                                                                    <span className='characters' style={{ color: "#DC5E5F" }}>C</span>
                                                                    <span className='characters' style={{ color: "#DC5E5F" }}>O</span>
                                                                    <span className='characters' style={{ color: "#DC5E5F" }}>S</span>
                                                                </div> :
                                                                el.type === 'Enterprising' ?
                                                                    <div className='elite-text-vertical' style={{ background: '#FFEBE1' }}>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>G</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>N</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>I</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>S</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>I</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>R</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>P</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>R</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>E</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>T</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>N</span>
                                                                        <span className='characters' style={{ color: "#EE824B" }}>E</span>
                                                                    </div> :
                                                                    <div className='elite-text-vertical' style={{ background: '#FDF8DF' }}>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>L</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>A</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>O</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>I</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>T</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>E</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>V</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>O</span>
                                                                        <span className='characters' style={{ color: "#DEC02E" }}>C</span>
                                                                    </div>
                                                }
                                                <div className='suggest-job-roles' style={{ background: el.type === 'Realistic' ? '' : el.type === 'Investigative' ? "#EBFAFE" : el.type === 'Artistic' ? '#EBF4FE' : el.type === 'Social' ? '#FFF3F3' : el.type === 'Enterprising' ? '#FFEBE1' : "#FDF8DF" }}>
                                                    <div style={{ width: window.innerWidth > 767 ? '30%' : '100%' }}>
                                                        <p>{getInterest(el.type, 'suggested')}</p>
                                                    </div>
                                                    <div className='job-roles row'>
                                                        <h6>Job role</h6>
                                                        {
                                                            getInterest(el.type, 'jobrole')?.map((el1, i) => {
                                                                return (
                                                                    <div className='col-md-6 col-12'>
                                                                        <div className={el.type === 'Realistic' ? 'job-div' : el.type === 'Investigative' ? 'job-div-i' : el.type === 'Artistic' ? 'job-div-a' : el.type === 'Social' ? 'job-div-s' : el.type === 'Enterprising' ? 'job-div-e' : el.type === 'Conventional' ? 'job-div-c' : null}>
                                                                            {el1}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {/* {
                        top3 && top3.slice(0, 1).map((el, i) => {
                            return (
                                <div className='suggested-career'>
                                    <div className='d-flex div1' >
                                        {el.type === 'Realistic' ?
                                            <div className='elite-text-vertical'>
                                                <span className='characters'>C</span>
                                                <span className='characters'>I</span>
                                                <span className='characters'>T</span>
                                                <span className='characters'>S</span>
                                                <span className='characters'>I</span>
                                                <span className='characters'>L</span>
                                                <span className='characters'>A</span>
                                                <span className='characters'>E</span>
                                                <span className='characters'>R</span>
                                            </div> :
                                            el.type === 'Investigative' ?
                                                <div className='elite-text-vertical' style={{ background: '#EBFAFE' }}>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>E</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>V</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>T</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>A</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>G</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>T</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>S</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>E</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>V</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>N</span>
                                                    <span className='characters' style={{ color: "#72B7D3" }}>I</span>
                                                </div> :
                                                el.type === 'Artistic' ?
                                                    <div className='elite-text-vertical' style={{ background: '#EBF4FE' }}>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>C</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>I</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>T</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>S</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>I</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>T</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>R</span>
                                                        <span className='characters' style={{ color: "#1D86A3" }}>A</span>
                                                    </div> :
                                                    el.type === 'Social' ?
                                                        <div className='elite-text-vertical' style={{ background: '#FFF3F3' }}>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>L</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>A</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>I</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>C</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>O</span>
                                                            <span className='characters' style={{ color: "#DC5E5F" }}>S</span>
                                                        </div> :
                                                        el.type === 'Enterprising' ?
                                                            <div className='elite-text-vertical' style={{ background: '#FFEBE1' }}>
                                                                <span className='characters' style={{ color: "#EE824B" }}>G</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>N</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>I</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>S</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>I</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>R</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>P</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>R</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>E</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>T</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>N</span>
                                                                <span className='characters' style={{ color: "#EE824B" }}>E</span>
                                                            </div> :
                                                            <div className='elite-text-vertical' style={{ background: '#FDF8DF' }}>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>L</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>A</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>O</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>I</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>T</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>E</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>V</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>N</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>O</span>
                                                                <span className='characters' style={{ color: "#DEC02E" }}>C</span>
                                                            </div>
                                        }
                                        <div className='suggest-job-roles' style={{ background: el.type === 'Realistic' ? '' : el.type === 'Investigative' ? "#EBFAFE" : el.type === 'Artistic' ? '#EBF4FE' : el.type === 'Social' ? '#FFF3F3' : el.type === 'Enterprising' ? '#FFEBE1' : "#FDF8DF" }}>
                                            <div style={{ width: window.innerWidth > 767 ? '30%' : '100%' }}>
                                                <p>{getInterest(el.type, 'suggested')}</p>
                                            </div>
                                            <div className='job-roles row'>
                                                <h6>Job role</h6>
                                                <div className='col-md-12 col-12'>
                                                    {
                                                        getInterest(el.type, 'jobrole')?.slice(0, 5)?.map((el1, i) => {
                                                            return (
                                                                <div className={el.type === 'Realistic' ? 'job-div' : el.type === 'Investigative' ? 'job-div-i' : el.type === 'Artistic' ? 'job-div-a' : el.type === 'Social' ? 'job-div-s' : el.type === 'Enterprising' ? 'job-div-e' : el.type === 'Conventional' ? 'job-div-c' : null}>
                                                                    {el1}
                                                                    <p>Average Salary: 2,10,000</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    } */}
                            {/* <div className='career-header'>
                        Other areas that may interest you
                    </div>
                    <div className='other-interest'>
                        {
                            lastThree && lastThree?.map(el => {
                                return (
                                    <div className='mt-2'>
                                        <h5 style={{ color: el.type === 'Social' ? '#DC5E5F' : el.type === 'Conventional' ? '#DEC02E' : el.type === 'Enterprising' ? '#EE824B' : el.type === 'Realistic' ? '#38A071' : el.type === 'Investigative' ? '#72B7D3' : '#1D86A3' }}>{el.type}</h5>
                                        <p>{getInterest(el.type, 'interest')}</p>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                        </div>
                    }
                </Fragment> :
                <Fragment>
                    <div className='other-reports'>
                        <h2 style={{ width: '88%', background: 'transparent' }} ><img className='cursor-pointer' src='/images/back.svg' alt='' onClick={() => back()} />&nbsp;&nbsp;Career Assessment Test Report
                            <div className='btns'>
                                <button onClick={() => downloadReportPdf1()}>Download PDF</button>&nbsp;
                                {/* <button>Share</button> */}
                            </div>
                        </h2>
                        <div className='ability-holder' id='report' style={{ width: '99%', background: 'transparent' }}>
                            <div className="table-admin" >
                                <Table responsive >
                                    <thead>
                                        <tr className="header-table">
                                            <th>Ability </th>
                                            <th className='text-center'>Questions</th>
                                            <th className='text-center'>Duration</th>
                                            <th className='text-center'>Raw score</th>
                                            <th className='text-center'>Sten score</th>
                                            <th className='text-center'>Performance</th>
                                            <th className='text-center'>Test Taken</th>

                                        </tr>
                                        <hr />
                                    </thead>
                                    <tbody className="table-body" >
                                        {
                                            (abilityDetails !== undefined && abilityDetails?.length > 0 ?
                                                abilityDetails?.map((el, i) => {
                                                    return (
                                                        <tr key={i} className='cursor-pointer'
                                                        >

                                                            <td style={{ width: '30%' }}  >
                                                                {el.title}
                                                            </td>
                                                            <td className='text-center'>{el.question_count}</td>
                                                            <td className='text-center'>{convertMillisecondsToTime(el.duration)}</td>
                                                            <td className='text-center'>{el.completed ? el.score : '-'}</td>
                                                            <td className='text-center'>{el.completed ? el.sten_score : '-'}</td>
                                                            <td className='text-center'>{el.sten_score <= 3 ? 'Low' : (el.sten_score > 3 && el.sten_score <= 7) ? 'Medium' : "High"}</td>
                                                            <td className='text-center'>{el.completed ? convertToDate(el.test_taken) : 'Not Taken'}</td>
                                                        </tr>
                                                    )
                                                })

                                                :
                                                <tr>
                                                    <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                    </div>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>


                            </div>
                            {
                                <Fragment>


                                    {
                                        abilityTrends?.data?.length ?
                                            <div className='row me-0 ms-0 mt-5'>
                                                <div className='col-md-10'>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={abilityTrend}
                                                    />
                                                </div>
                                                <div className="html2pdf__page-break"></div>
                                                <div className='d-flex align-items-center flex-wrap mt-5'>
                                                    {
                                                        abilityDetails && abilityDetails?.map((el, i) => {
                                                            return (
                                                                <div className='career-interest-progress mt-2'>
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: el.title === 'Verbal Ability (VA)' ? "#F1FEEB" : el.title === 'Numerical ability (NA)' ? '#EBFAFE' : el.title === 'Spatial ability (SA)' ? "#EBF4FE" : el.title === 'Closure ability (CA)' ? "#FFF3F3" : el.title === 'Clerical ability (CL)' ? '#FFEBE1' : el.title === 'Reasoning ability (RA)' ? "#FDF8DF" : el.title === 'Mechanical ability (MA)' ? '#FFEBE1' : '#FFF3F3',
                                                                            width: '88%', padding: 15
                                                                        }}
                                                                    >
                                                                        <h6>
                                                                            {el.title}
                                                                        </h6>
                                                                        <p>{getDescByTitle(el.title)}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div> :
                                            null
                                    }

                                    <div className="html2pdf__page-break"></div>
                                    {data?.name === 'tamanna' ?
                                        <div className='t-ability row me-0 ms-0 mt-5'>
                                            {TamannaAbility && TamannaAbility?.map(el => {
                                                return (
                                                    <div className='col-md-4 col-6 mt-3'>
                                                        <h4>{el.occupation}</h4>
                                                        <ul>
                                                            {
                                                                el.ability.split(',')?.map(el1 => {
                                                                    return (
                                                                        <li>{el1}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            })}
                                        </div> :
                                        null
                                    }


                                </Fragment>
                            }

                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}
