import React, { Fragment, useEffect, useState } from 'react';
import '../Counselling/counselling.scss';
import { useTranslation } from 'react-i18next';
import Table from 'react-bootstrap/Table';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useDispatch } from 'react-redux';
import { getReportList, getReport, resetReport } from '../../Redux/ActionCreators/counsellingAction';
import { useSelector } from 'react-redux';
import { convertDate, formatAMPM, getCodes, goFullScreen, exitFullscreen, getInterest } from '../../utils/miscelleneous';
import TestReport from '../Counselling/test-report';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { getProfileDetails } from '../../Redux/ActionCreators/profileAction';
// import SelfTest from '../Counselling/selfTest';
const SelfTest = React.lazy(() => import('../Counselling/selfTest'));

export default function CareerAssessment() {
    const [testTypes, setTestTypes] = useState([]);
    const [test, setTest] = useState(false);
    const [data, setData] = useState('');
    const [isReport, setIsReport] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState(null);
    const { reportList, report, loading, testReport } = useSelector(state => state.counselling);


    useEffect(() => {
        const firstRender = async () => {
            const res1 = await dispatch(getProfileDetails());
            if (res1?.type === 'GET_PROFILE') {
                setTestTypes(res1?.payload.tests);
            }
            const res = await dispatch(getReportList());
            if (res.payload?.data?.length > 0) {
                dispatch(getReport())
            }
        }
        firstRender();
    }, [])


    const getOneReports = async (data) => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        await dispatch(resetReport());
        setData(data);
        setIsReport(true);
    }


    const takeTest = () => {
        setIsOpen(false);
        setTest(true);
        goFullScreen();
    }

    const closeFullScreen = async () => {
        await setTest(false);
        await setIsReport(true);
        await exitFullscreen();
        await dispatch(resetReport());
        await dispatch(getReport());
    }

    const back = async () => {
        await setIsReport(false);
        await dispatch(resetReport());
        await dispatch(getReport());
        await dispatch(getReportList());
    }

    const takeDBDATest = () => {
        setIsOpen(false);
        navigate('/career-ability', { state: { id: type?._id } });
    }

    const uniques = testTypes.filter((item, index, self) =>
        index === self.findIndex(t => t._id === item._id)
    )

    return (
        <Fragment>
            {!isReport ?
                <div className='counselling-holder'>
                    <div className='counselling-introduction'>
                        <div className='introduction'>
                            <h3>{t('Explore career paths with our comprehensive assessment test!')}</h3>
                            {window.innerWidth > 767 &&
                                <p>{t('Uncover hidden talents, identify suitable career paths, and gain valuable insights to shape your future. Let the assessment be your compass on the road to a fulfilling and successful career. Start the adventure today!')}</p>}

                            <div className='counselling-btn'>
                                {window.innerWidth > 767 &&
                                    <div className='d-flex align-items-center'>
                                        {uniques?.map(el => {
                                            return (

                                                <button
                                                    onClick={() => {
                                                        setIsOpen(true)
                                                        setType(el)
                                                    }}
                                                >Take <span className='text-uppercase'>{el.name}</span>  test</button>
                                            )
                                        })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='counselling-img' >
                            {window.innerWidth > 767 ?
                                <img src='/images/career-assesment-img.png' width='398px' alt='' /> :
                                <img src='/images/coun-img4.svg' alt='' />
                            }
                        </div>
                    </div>
                    {testTypes && testTypes?.filter(el => el.enable_report)?.length > 0 ?
                        <div className='report-table'>
                            <h3>Reports</h3>
                            <Table responsive>
                                <thead>
                                    {/* <th>Text taken on</th> */}
                                    <th>Test name</th>
                                    <th>Action</th>
                                </thead>
                                <tbody className="table-body">
                                    {
                                        testTypes && testTypes?.filter(el => el.enable_report)?.map(el => {

                                            return (
                                                <tr>
                                                    {/* <td>{convertDate(el.createdAt)}</td> */}
                                                    <td style={{ textTransform: 'uppercase', }}>{el.name}</td>
                                                    <td >
                                                        <p style={{ cursor: 'pointer', color: '#333269' }} onClick={() => { getOneReports(el) }}>View Detailed report</p></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                        :
                        null
                    }
                    <div className='how-to-works'>
                        <h4>{t('How Does it works ?')}</h4>
                        <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto'}>
                            <div className='col-md-4'>
                                <img src='/images/assess-img1.png' alt='' />
                                <h5>{t('Take The Assessment')}</h5>
                                <p>{t('Tests only take 20 mins to complete')}</p>
                            </div>
                            <div className='col-md-4'>
                                <img src='/images/assess-img2.png' alt='' />
                                <h5>{t('Answer questions')}</h5>
                                <p>{t('Empower your professional journey by booking a personalized career counseling session')}</p>
                            </div>
                            <div className='col-md-4'>
                                <img src='/images/assess-img3.png' alt='' />
                                <h5>{t('Receive Instant Results')}</h5>
                                <p>{t('100% digital, confidential, and science-based custom report')}</p>
                            </div>
                        </div>
                    </div>

                    <div className='why-counselling'>
                        {window.innerWidth > 767 &&
                            <div className='counselling-img' >
                                <img src='/images/coun-img5.png' alt='' width={445} height={305} />
                            </div>}
                        <div className='introduction'>
                            <p>{t('Reasons to Consider Taking a Career Assessment')}</p>
                            <p style={{ color: '#272727', fontWeight: 400, lineHeight: 1.5 }}>{t(`As you move beyond high school and into the next chapter of your life, you'll encounter a series of complex and highly significant decisions to navigate.`)} </p>
                            <div className='counselling-points'>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Gain self-awareness')}
                                    </h6>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Discover new career options')}
                                    </h6>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Narrow down your options')}
                                    </h6>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img src='/images/tick.svg' alt='' />
                                    <h6>
                                        {t('Develop a personalized action plan')}
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="self-assessment-test-view" style={{ zIndex: window.innerWidth > 767 ? 8 : 9, display: test ? "block" : "none", top: 0 }}>
                        <SelfTest
                            test={test}
                            id={testTypes?.filter(el => el.name === 'riasec')[0]?._id}
                            closeFullScreen={closeFullScreen}
                        />
                    </div>
                </div>
                :
                <TestReport
                    loading={loading}
                    data={data}
                    back={back}
                />
            }
            {/* ########## Confirm submission ############### */}
            <Modal
                show={isOpen}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"confirm-modal"}
            >
                <div className="p-4 text-center">
                    <h1 className='mb-2'>{`Note, you can only take this test once. Are you sure you want to start now?`}</h1>
                    <div className="save-btn mt-4">
                        <button onClick={() => type?.name === 'riasec' ? takeTest() : takeDBDATest()}>Yes</button> &nbsp;&nbsp;
                        <button onClick={() => setIsOpen(false)}>No</button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}
